import React, { useState, useEffect, useCallback } from "react";
import API_URL from './config';
import { token, headers } from './headers';

function PopupFolder(props) {
  const [directories, setDirectories] = useState([]);
  const [currentPath, setCurrentPath] = useState();
  const [collectionName, setCollectionName] = useState();

  const handleClick = useCallback((newpath) => {
    if (currentPath === newpath)
      return
    setCurrentPath(newpath);
    fetch(`${API_URL}/view/Dir/`, {
      method: "POST",
      headers: {
        "Authorization" : `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        currentPath: newpath,
        subPath: "",
        getParent: "",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setDirectories(data.subListDirectory);
        props.onButtonClick(data.currentPath);
      });
  }, [props]);

  function handleSaveClick() {
    fetch(`${API_URL}/view/catalog/analyse/`, {
      method: "POST",
      headers: {
        "Authorization" : `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        path: currentPath,
        name: collectionName,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        // Do something with the response data
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });
    props.handleClose();
  }

  useEffect(() => {
    handleClick(props.currentPath);
  }, [props.currentPath, handleClick]);

  return (
    <div className="modal" style={{ display: "flex" }}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head panel-heading">
          <div className="field modal-card-title">
            <div className="label notification is-small is-dark has-text-primary" style={{margin: "20px 0 20px 0"}}>Current Path: {!currentPath ? props.currentPath : currentPath}
            </div>
            <label className="label">Nom de la collection</label>
            <div className="control" data-dashlane-rid="6e7aac844f792d49" data-form-type="other">
              <input className="input is-small" type="text" name="name" placeholder="Text input" data-form-type="other" value={collectionName}
                onChange={(event) => setCollectionName(event.target.value)} />
            </div>
          </div>
          <button
            className="delete popupdelete"
            aria-label="close"
            onClick={props.handleClose}
          ></button>
        </header>
        <section className="modal-card-body custom-modal">

          {directories.map((directory) => (
            <a className="panel-block"
              key={directory.path}
              onClick={() => handleClick(directory.path)}
            >
              <span className="panel-icon">
                <i className="fas fa-folder" aria-hidden="true"></i>
              </span>
              {directory.name}
            </a>
          ))}
        </section>
        <footer className="modal-card-foot">
          <button className="button is-success" onClick={() => handleSaveClick()}>
            Add Collection
          </button>
        </footer>
      </div>
    </div>
  );
}

export default PopupFolder;
