import React from 'react';
import AlbumItem from './AlbumItem';

function AlbumsGrid({ comics, handleOpenPopup, divRef, index, size}) {
    return (
        <div className={size === true ? "board-right-grid" : "board-right-grid-small"}>
            {comics.map((item, indexItem) => (
                <AlbumItem
                    key={item.Guid}
                    item={item}
                    index={index + indexItem}
                    handleOpenPopup={handleOpenPopup}
                    divRef={divRef}
                />
            ))}
        </div>
    );
}

export default AlbumsGrid;