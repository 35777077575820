import React, { useState } from 'react';
import BoardLeft from './BoardLeft';
import BoardRight from './BoardRight';
import Loader from './Loader';
import API_URL from './config';
import { token, headers } from './headers';

function Board() {
  const [selectedItem, setSelectedItem] = useState(null);
  const [jsonData, setJsonData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function handleItemSelect(item) {
    if (item === selectedItem) {
      return
    }
    setSelectedItem(item);
    setIsLoading(true);
    console.log(headers)
    fetch(`${API_URL}/view/catalog/` + item.guid, { headers })
    .then((response) => response.json())
    .then((data) => {
      setJsonData(data);
      setIsLoading(false); // Termine le chargement
    })
    .catch((error) => {
      console.log(error);
      setIsLoading(false); // Termine le chargement même en cas d'erreur
    });
  }

  return (
    <div className="board">
      <BoardLeft onItemSelect={handleItemSelect} />
      {isLoading ? <Loader /> : <BoardRight selectedItem={selectedItem} comics={jsonData?.subListComics} folders={jsonData?.subListDirectory} />}
    </div>
  );
}

export default Board;