import React, { useState, useEffect } from "react";
import API_URL from './config';
import { headers } from "./headers";


function BoardLeftItem(props) {

    const [item, setItem] = useState();

    function getItems(item) {
        console.log(`${API_URL}/view/catalogs/`);
        fetch(`${API_URL}/view/catalogs/`, { headers })
            .then((response) => response.json())
            .then((data) => {
                let items = data?.subListDirectory;
                if (items && items.length > 0) {
                    items.forEach((itemU) => {
                        if (item.guid === itemU.guid) {
                            if (item.itemsnumber !== itemU.itemsnumber) {
                                setItem(itemU);
                                setTimeout(() => {
                                    getItems(itemU);
                                  }, 5000);
                            } else {
                                setTimeout(() => {
                                    getItems(itemU);
                                  }, 100000);
                            }
                        }
                    });
                }
            }); // Added a closing parenthesis to fix the syntax error
    }

    useEffect(() => {
        setItem(props.item)
        setTimeout(() => {
            getItems(props.item);
          }, 2000);
    }, []);

    return (
        <>
        {item ? (
            <>
              <span>{item.title}</span>
              <span className="is-size-7"> ({item.itemsnumber})</span>
            </>
          ) : null}
        </>
    );
}

export default BoardLeftItem;