import React from 'react';
import FolderItem from './FolderItem';

function FolderGrid({ folders, handleRightItemSelect, divRef, size }) {
    return (
        <div className={size === true ? "board-right-grid-folders" : "board-right-grid-folders-small"}>
            {folders.map((item, index) => (
                <FolderItem
                    key={item.guid}
                    item={item}
                    index={index}
                    handleRightItemSelect={handleRightItemSelect}
                    divRef={divRef}
                />
            ))}
        </div>
    );
}

export default FolderGrid;