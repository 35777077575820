// headers.js
let token = localStorage.getItem('token');
let headers = { Authorization: `Bearer ${token}` };

function updateToken(newToken) {
  token = newToken;
  localStorage.setItem("token", newToken);
  headers = { Authorization: `Bearer ${token}` };
}

export { token, headers, updateToken };
