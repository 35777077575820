import React, { useState } from "react";
import API_URL from './config';
import { token, headers, updateToken } from './headers';

function Login({ refresh }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${API_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });
      if (response.ok) {
        const data = await response.json();
        updateToken(data.token)
        refresh();
        setError(null);
      } else {
        setError("Invalid username or password");
      }
    } catch (error) {
      console.error(error);
      setError("An error occurred");
    }
  };

  return (
<div className="columns is-centered is-vcentered is-fullheight">
      <div className="column is-one-third">
          <figure className="image container">
          <img src="/dragon.jpg" className="is-rounded" style={{padding: "50px"}}></img>
          </figure>
          <form onSubmit={handleLogin} className="mx-3">
            {error && <div className="notification is-danger">{error}</div>}
            <div className="field">
              <label className="label">Username:</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={username}
                  onChange={(event) => setUsername(event.target.value)}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Password:</label>
              <div className="control">
                <input
                  className="input"
                  type="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </div>
            </div>
            <button className="button is-primary is-fullwidth is-focused" type="submit">
              <span className="icon"><i className="fa-solid fa-user"></i></span><span>Login</span>
            </button>
          </form>
        </div>
      </div>
  );
}

export default Login;