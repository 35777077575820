import React, { useEffect, useRef } from 'react';

function FadeInImage({ src, alt }) {
  const imgRef = useRef();

  useEffect(() => {
    // const observer = new IntersectionObserver(entries => {
    //   // Pour chaque entrée, vérifiez si elle est visible
    //   entries.forEach(entry => {
    //     if (entry.isIntersecting) {
    //       // Ajoutez la classe 'fade-in' pour lancer l'animation
    //       entry.target.classList.add('fade-in');
    //     //   console.log(entry.target.src);
    //     }
    //     else {
    //       // Supprimez la classe 'fade-in'
    //       entry.target.classList.remove('fade-in');}
    //   });
    // }, { threshold: 0.1 }); // 10% de l'élément doit être visible

    const observer10 = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && entry.intersectionRatio >= 0.05) {
            // Logique pour un seuil de 10%
            entry.target.classList.add('fade-in-10');
            console.log("10% visible:", entry.target.src);
          } else {
            entry.target.classList.remove('fade-in-10');
          }
        });
      }, { threshold: 0.05 });
      
      // Observateur pour un seuil de 30%
      const observer30 = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && entry.intersectionRatio >= 0.3) {
            // Logique pour un seuil de 30%
            // entry.target.classList.remove('fade-in-10');
            entry.target.classList.add('fade-in-30');
            console.log("30% visible:", entry.target.src);
          } else {
            entry.target.classList.remove('fade-in-30');
          }
        });
      }, { threshold: 0.3 });
      
    
    // Observez l'image référencée
    if (imgRef.current) {
        observer10.observe(imgRef.current);
        observer30.observe(imgRef.current);
    }

    // Nettoyez l'observer quand le composant est démonté
    return () => {
      if (imgRef.current) {
        // observer.unobserve(imgRef.current);
        observer10.unobserve(imgRef.current);
        observer30.unobserve(imgRef.current);
      }
    };
  }, []);

  return (
    <img
      ref={imgRef}
      src={src}
      alt={alt}
      style={{ opacity: "0"}}
    //   className="fade-in" // Ajoutez cette classe par défaut
    />
  );
}

export default FadeInImage;