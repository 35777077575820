import React, { useState, useEffect, useRef } from "react";
import PopupComicDetails from "./PopupComicDetails";
import FolderGrid from './FolderGrid';
import AlbumsGrid from "./AlbumsGrid";
import Loader from "./Loader";
import API_URL from './config';
import { token, headers } from './headers';

function BoardRight({ selectedItem, comics: initialComics, folders: initialFolders }) {
  const [comics, setComics] = useState([]);
  const [folders, setFolders] = useState([]);
  const [previousItems, setPreviousItems] = useState([]);
  const [previousIndex, setPreviousIndex] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [currentIndex, setCurrentIndex] = useState();
  const [height, setHeight] = useState(window.innerHeight);
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [itemOpen, setItemOpen] = useState()
  const divRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [bigDisplay, setBigDisplay] = useState(true);


  function getNewFolder(item, index, back) {
    let url = ""
    if (selectedItem.guid !== item.guid)
      url = `${API_URL}/view/folder/` + selectedItem.guid + '/' + item.guid;
    else
      url = `${API_URL}/view/catalog/` + selectedItem.guid;
    console.log('ITEM : ' + url);
    setIsLoading(true)
    fetch(url, { headers })
      .then((response) => response.json())
      .then((data) => {
        setComics(data.subListComics);
        setFolders(data.subListDirectory);
        back ? previousItems.pop() : setPreviousItems([...previousItems, currentItem]);
        back ? previousIndex.pop() : setPreviousIndex([...previousIndex, index]);
        setIsLoading(false)
      })
      .catch((error) => console.log(error));
    setCurrentItem(item);
    back ? setCurrentIndex(index) : setCurrentIndex(0);
  }

  function handleRightItemSelect(item, index) {
    getNewFolder(item, index, false);
  };

  function handleBackButtonClick() {
    getNewFolder(previousItems.slice(-1)[0], previousIndex.slice(-1)[0], true)
  }

  function handleDisplayClick() {
    setBigDisplay(!bigDisplay);
    console.log(bigDisplay);
  }

  function handleOpenPopup(guid) {
    setItemOpen(guid)
    setPopupIsOpen(true);
  }

  function handleClosePopup() {
    setPopupIsOpen(false);
  }

  useEffect(() => {
    setComics(initialComics);
    setFolders(initialFolders);
    setCurrentItem(selectedItem);
    setPreviousItems([]);
    setCurrentIndex(0);
    setPreviousIndex([]);
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [initialComics, initialFolders, selectedItem]);

  /*useEffect(() => {
    const timeout = setTimeout(() => {
      if (!selectedItem)
        return;
      console.log("INDEX : " + currentIndex)
      divRefs.current[currentIndex].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
    }, 1000);
    return () => clearTimeout(timeout);
  }, [folders]);*/

  return (
    <div className="board-right">
      {popupIsOpen && <PopupComicDetails handleClose={handleClosePopup} guid={itemOpen} />}
      {isLoading ? <Loader /> : (
        <>
          {selectedItem ? (
            <>
              <div className="columns m-2 top_bar_right">
                <div className="box_folder column is-1" column onClick={() => handleDisplayClick()}><span className="icon-grid_icon" style={{fontSize: '32px'}}></span></div>
                {previousItems.length > 0 ? (
                  <div className="box_folder column" key="back-button" onClick={() => handleBackButtonClick()} style={{ cursor: "pointer" }}>
                    <div><i className="fa-solid fa-turn-up fa-flip-horizontal"></i></div>
                    <p style={{ padding: '10px' }}>{currentItem.title} - {currentItem.itemsnumber}</p>
                  </div>
                ) : (
                  <div className="box_folderN column" key="back-button" style={{ cursor: "default" }}>
                    <p style={{ padding: '10px' }}>{selectedItem.title} - {selectedItem.itemsnumber}</p>
                  </div>
                )}
              </div>
              <div style={{ height: `${height - 80}px`, overflow: 'auto' }}>
                {folders && folders.length > 0 && (
                  <div style={{ color: 'white', paddingLeft: '20px', paddingBottom: '80px' }}>
                    <p>FOLDERS</p>
                    <FolderGrid
                      folders={folders}
                      handleRightItemSelect={handleRightItemSelect}
                      divRef={divRefs}
                      size={bigDisplay}
                    />
                  </div>
                )}
                {comics && comics.length > 0 && (
                  <div style={{ color: 'white', paddingLeft: '20px' }}>
                    <p>ALBUMS</p>
                    <AlbumsGrid
                      comics={comics}
                      handleOpenPopup={handleOpenPopup}
                      divRef={divRefs}
                      index={folders ? folders.length : 0}
                      size={bigDisplay}
                    />
                  </div>
                )}
                {(!folders || folders.length === 0) && (!comics || comics.length === 0) && (
                  <p style={{ color: 'white', paddingLeft: '20px' }}>AUCUN ALBUMS ICI</p>
                )}
              </div>
            </>
          ) : (
            <p style={{ color: 'white', display: 'flex', justifyContent: 'center', top: '15px', position: 'relative' }}>SELECT A LIBRARY</p>
          )}
        </>
      )}
    </div>
  );

}


export default BoardRight;
