import React, { useState, useEffect } from "react";
import API_URL from "./config";
import { token, headers } from './headers';

function PopupComicDetails(props) {
  const [comicDetail, setComicDetail] = useState();
  const [serie, setSerie] = useState([]);
  const [currentAlbum, setCurrentAlbum] = useState();
  const [page, setPage] = useState(0)

  useEffect(() => {
    if (currentAlbum === props.guid)
      return
    let guid = currentAlbum;
    if (!currentAlbum) {
      setCurrentAlbum(props.guid);
      guid = props.guid;
    }
    fetch(`${API_URL}/view/album/` + guid, { headers })
      .then((response) => response.json())
      .then((data) => {
        setComicDetail(data);
      })
      .catch((error) => console.log(error));
    fetch(`${API_URL}/view/serie/` + guid, { headers })
      .then((response) => response.json())
      .then((data) => {
        setSerie(data.guids);
      })
      .catch((error) => console.log(error));
  }, [currentAlbum, props.guid]);

  function downloadAlbum(albumId) {
    fetch(`${API_URL}/download/album/` + albumId, { headers })
      .then(response => {
        const filename = `${comicDetail.series} #${comicDetail.number} - ${comicDetail.title}.cbz`
        console.log(filename)
        return response.blob().then(blob => {
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        });
      })
      .catch(error => console.error(error));
  }

  return (
    <div className="modal" style={{ display: "flex" }} onClick={props.handleClose}>
      <div className="modal-background"></div>
      <div className="modal-card" onClick={event => event.stopPropagation()}>
        <div className="card" style={{ overflow: "auto" }}>
          <header className="modal-card-head panel-heading">
            {comicDetail ? (
              <div className="field modal-card-title">
                <p className="title is-4">{comicDetail.series}</p>
              </div>
            ) : (
              <div></div>
            )}
            <button
              className="delete popupdelete"
              aria-label="close"
              onClick={props.handleClose}
            ></button>
          </header>
          <div className="card-content">
            <div className="media">
              {currentAlbum ? (
                <div className="media-left albumDetailLeft">
                  <figure className="image">
                    <img
                      src={`${API_URL}/view/image/${currentAlbum}`}
                      alt="pictures"
                    />
                  </figure>
                </div>) : (<div></div>)}
              {comicDetail ? (
                <div className="media-content">
                  <p className="title is-4">{comicDetail.title}</p>
                  <p className="subtitle is-6">
                    {comicDetail.number} - {comicDetail.title}
                  </p>

                  <p className="content is-small summary">
                    {comicDetail.summary}
                  </p>
                  <p>...</p>
                </div>
              ) : (
                <div className="media-content" />
              )}
            </div>
            {serie.length > 1 ? (
              <>
                <nav className="pagination is-centered is-small" role="navigation" aria-label="pagination">
                  {page > 0 ? (
                    <a className="pagination-previous" onClick={() => setPage(page - 4)}>Previous</a>
                  ) : (<a className="pagination-previous is-disabled">Previous</a>)}
                  <span className="pagination-list">{(page / 4) + 1}/{Math.ceil(serie.length / 4)}</span>
                  {page < serie.length - 4 ? (
                    <a className="pagination-next" onClick={() => setPage(page + 4)}>Next</a>
                  ) : (<a className="pagination-next is-disabled">Next</a>)}
                </nav>
                <div className="media">
                  {serie.slice(page, page + 4).map((album) => (
                    <div className="media_content_albums_list">
                      <img
                        key={album}
                        style={{
                          width: "120px",
                          padding: "5px",
                        }}
                        className="image is-clickable"
                        src={`${API_URL}/view/image/${album}`}
                        onClick={() => setCurrentAlbum(album)}
                        alt="pictures"
                      />
                    </div>
                  ))}
                </div></>) : (<div></div>)}
          </div>
          <footer className="modal-card-foot">
            <a href={`${API_URL}/download/album/${currentAlbum}`}>Download Comics</a>
            <button className="button is-success" onClick={() => downloadAlbum(currentAlbum)}>Download Comics</button>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default PopupComicDetails;
