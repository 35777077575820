import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Board from "./Board";
import reportWebVitals from "./reportWebVitals";
import { token, headers } from "./headers";
import API_URL from "./config";
import Login from "./login";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <App />
  </>
);

function App() {
  const [isLogged, setisLogged] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);

  function refresh() {
    setRefreshCounter(refreshCounter + 1);
  }

  useEffect(() => {
    fetch(`${API_URL}/view/catalogs/`, { headers })
      .then((response) => response.json())
      .then((data) => {
        setisLogged(true);
      });
  }, [refreshCounter]);

  return (
    <>
      {isLogged ? <Board /> : <Login refresh={refresh} />}
      {/*{(!token || token == '') && <Login/>}
      {token && token !== '' && <Board />}*/}
    </>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
