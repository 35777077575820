import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import FadeInImage from './FadeInImage';
import 'react-lazy-load-image-component/src/effects/blur.css';
import API_URL from './config';

function FolderItem({ item, handleRightItemSelect, index, divRef }) {
    return (
        <div 
            className="box"
            style={{ cursor: "pointer" }}
            onClick={() => handleRightItemSelect(item, index)}
            ref={el => {
                divRef.current[index] = el;
            }}
        >
            {item.thumbnailLink ? (
                <FadeInImage
                    src={`${API_URL}/view/image/${item.thumbnailLink}`}
                    alt={item.title}
                    effect="blur"
                />
            ) : (
                <FadeInImage alt="folder" src={`folder.jpg`} effect="blur" />
            )}
            <div style={{ position: 'absolute', color: 'white', top: '0', right: '5px', width: '50px', height: '150px', backgroundColor: 'red', background: 'radial-gradient(circle, rgba(255, 0, 0, 1), rgba(0, 0, 0, 0.7))' }}>{item.itemsnumber}</div>
            <div style={{ backgroundColor: 'rgb(63 81 181 / 35%)' }}>
                <p style={{ color: 'white', backgroundColor: 'rgb(63 81 181 / 0%)' }}>{item.title}</p>
            </div>
        </div>
    );
}

export default FolderItem;