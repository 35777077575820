import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import API_URL from './config';

function AlbumItem({ item, handleOpenPopup, index, divRef }) {
    return (
        <div className="box" 
        style={{ cursor: "pointer" }} 
        ref={el => {
            divRef.current[index] = el;
        }}>
            {item.thumbnailLink && (
                <LazyLoadImage
                    src={`${API_URL}/view/image/${item.Guid}`}
                    alt={item.title}
                    onClick={() => handleOpenPopup(item.Guid)}
                    effect="blur"
                />
            )}
            <div>
                <p>{item.title}</p>
            </div>
            <a className="button is-success" href={`${API_URL}/download/album/${item.Guid}`}>Download Comics</a>
        </div>
    )
};

export default AlbumItem;