import React, { useState, useEffect, forwardRef } from "react";
import PopupFolder from "./PopupFolder";
import BoardLeftItem from "./BoardLeftItem" 
import API_URL from "./config";
import { token, headers } from "./headers";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

function BoardLeft({ onItemSelect }) {
  const [selectedItem, setSelectedItem] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    path: "",
  });
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [height, setHeight] = useState(window.innerHeight);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  let tippyInstance = [];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  function getItems() {
    console.log(`${API_URL}/view/catalogs/`);
    fetch(`${API_URL}/view/catalogs/`, { headers })
      .then((response) => response.json())
      .then((data) => {
        tippyInstance.splice(0, tippyInstance.length);
        setItems(data?.subListDirectory);
      });
  }

  useEffect(() => {
    getItems();
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [popupIsOpen]);

  function handleOpenPopup() {
    setPopupIsOpen(true);
  }

  function handleClosePopup() {
    setPopupIsOpen(false);
    getItems();
  }

  function handlePopupFolderCallback(currentPath) {
    setFormData({
      ...formData,
      path: currentPath,
      name: formData.name, // Inclure toutes les clés de l'objet existant
    });
  }

  function handleItemClick(item) {
    setSelectedItem(item);
    onItemSelect(item);
  }

  function handleRefreshItem(item) {
    fetch(`${API_URL}/view/catalog/refresh/` + item.guid, { headers })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        getItems();
        // Do something with the response data
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });
  }

  function handleRefreshItemImages(item) {
    console.log("/view/catalog/refreshImages/"+item.guid)
    fetch(`${API_URL}/view/catalog/refreshImages/` + item.guid, { headers })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        getItems();
        // Do something with the response data
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });
  }

  function handleRefreshItemMeta(item) {
    console.log("/view/catalog/refreshMeta/"+item.guid)
    fetch(`${API_URL}/view/catalog/refreshMeta/` + item.guid, { headers })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        getItems();
        // Do something with the response data
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });
  }
  

  function handleDeleteItem(item) {
    fetch(`${API_URL}/view/catalog/delete/` + item.guid, { headers })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        getItems();
        // Do something with the response data
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });
  }

  const CustomChild = forwardRef((props, ref) => {
    return (
      <div ref={ref} className="item-tooltip">
        <span className="icon  has-text-info">
          <i className="fa-solid fa-ellipsis-vertical"></i>
        </span>
      </div>
    );
  });

  function hideAllTooltips(){
    tippyInstance.forEach((instance) => {
      instance.hide();
    });
  }

  return (
    <><div className="burger-menu" onClick={toggleMenu}>
      <i className="fa-solid fa-bars"></i>
    </div>
    {popupIsOpen && (
          <PopupFolder
            handleClose={handleClosePopup}
            onButtonClick={handlePopupFolderCallback}
            currentPath={formData.path} />
        )}
    <div className={`board-left ${isMenuOpen ? 'is-active' : ''}`} style={{ height: `${height}px` }}>
        <img src="/dragon.jpg"></img>
        <div className="block newcollection">
          <button
            className="button is-primary is-large is-fullwidth"
            data-form-type="action"
            onClick={handleOpenPopup}
          >
            Add Collection
          </button>
        </div>
        <div className="itemsList">
          {items && items.length > 0 ? (
            <ul>
              {" "}
              {items.map((item) => (
                <li
                  key={item.guid}
                  className={selectedItem && selectedItem.guid === item.guid
                    ? "selected"
                    : "item"}
                  style={{ cursor: "pointer" }}
                >
                  <span className="icon-text icon-text is-flex align-content: flex-start">
                    <div
                      className="ml-2 is-flex-grow-4 is-size-7"
                      onClick={() => handleItemClick(item)}
                      id={item.guid}
                    >
                      <BoardLeftItem item={item} />
                    </div>
                    <Tippy
                      content={<>
                        <div
                          className="item-trash"
                          onClick={() => { handleDeleteItem(item); hideAllTooltips(); } }
                        >
                          <span className="icon has-text-danger">
                            <i className="fa-solid fa-trash"></i>
                          </span>
                          <span style={{ verticalAlign: "middle" }}>Supprimer la librairie</span>
                        </div>
                        <div
                          className="item-refresh"
                          onClick={() => { handleRefreshItem(item); hideAllTooltips(); } }
                        >
                          <span className="icon  has-text-info">
                            <i className="fa-solid fa-arrows-rotate"></i>
                          </span>
                          <span>Raffraichir la librairie</span>
                        </div>
                        <div
                          className="item-refresh"
                          onClick={() => { handleRefreshItemImages(item); hideAllTooltips(); } }
                        >
                          <span className="icon  has-text-info">
                            <i className="fa-solid fa-arrows-rotate"></i>
                          </span>
                          <span>Raffraichir les images</span>
                        </div>
                        <div
                          className="item-refresh"
                          onClick={() => { handleRefreshItemMeta(item); hideAllTooltips(); } }
                        >
                          <span className="icon  has-text-info">
                            <i className="fa-solid fa-arrows-rotate"></i>
                          </span>
                          <span>Raffraichir les Metadata</span>
                        </div>
                      </>}
                      trigger="click"
                      interactive="true"
                      onCreate={(instance) => tippyInstance.push(instance)}
                    >
                      <CustomChild item={item} />
                    </Tippy>
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <p>Empty</p>
          )}
        </div>
      </div></>
  );
}

export default BoardLeft;
